import revive_payload_client_wm97YFzsap from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.17.5_@unocss+reset@0.63.3_eslint@9.10.0_floating-vue@5.2.2_jwt-dec_syiahgqhxrfd6v4d3sr3lvmpwu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UsJcu3qsAX from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.17.5_@unocss+reset@0.63.3_eslint@9.10.0_floating-vue@5.2.2_jwt-dec_syiahgqhxrfd6v4d3sr3lvmpwu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_n5wKhzFpOq from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.17.5_@unocss+reset@0.63.3_eslint@9.10.0_floating-vue@5.2.2_jwt-dec_syiahgqhxrfd6v4d3sr3lvmpwu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MfU0D3dslB from "/usr/prnews/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import posthog_client_6rriGpSAc0 from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_rollup@4.24.0/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import supabase_client_nMtrdgTuty from "/usr/prnews/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_rollup@4.24.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_4rIrpA3iCP from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.17.5_@unocss+reset@0.63.3_eslint@9.10.0_floating-vue@5.2.2_jwt-dec_syiahgqhxrfd6v4d3sr3lvmpwu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_pSHQS3iZ0f from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.17.5_@unocss+reset@0.63.3_eslint@9.10.0_floating-vue@5.2.2_jwt-dec_syiahgqhxrfd6v4d3sr3lvmpwu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_6N9gFfZx5f from "/usr/prnews/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.24.0_typescript@5.6.2_vue@3.5.11/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/prnews/.nuxt/components.plugin.mjs";
import prefetch_client_sFdSHx5gaE from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.17.5_@unocss+reset@0.63.3_eslint@9.10.0_floating-vue@5.2.2_jwt-dec_syiahgqhxrfd6v4d3sr3lvmpwu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_CGAvNNqqy8 from "/usr/prnews/node_modules/.pnpm/nuxt-echarts@0.2.3_echarts@5.5.1_rollup@4.24.0/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import sentry_client_qJAwkxuHZw from "/usr/prnews/node_modules/.pnpm/@sentry+nuxt@8.28.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_7ep3xedxc3q5bhgy23jiyujziq/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/prnews/.nuxt/sentry-client-config.mjs";
import directives_BRbw7yolFT from "/usr/prnews/node_modules/.pnpm/nuxt-posthog@1.5.2_rollup@4.24.0/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import i18n_GS1gL0vY8V from "/usr/prnews/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_RK9Gq86PkW from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.17.5_@unocss+reset@0.63.3_eslint@9.10.0_floating-vue@5.2.2_jwt-dec_syiahgqhxrfd6v4d3sr3lvmpwu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import validate_fk94mvjWUp from "/usr/prnews/plugins/validate.ts";
import vueQuery_HbXfDZmQp2 from "/usr/prnews/plugins/vueQuery.ts";
export default [
  revive_payload_client_wm97YFzsap,
  unhead_UsJcu3qsAX,
  router_n5wKhzFpOq,
  _0_siteConfig_MfU0D3dslB,
  posthog_client_6rriGpSAc0,
  supabase_client_nMtrdgTuty,
  payload_client_4rIrpA3iCP,
  check_outdated_build_client_pSHQS3iZ0f,
  plugin_vue3_6N9gFfZx5f,
  components_plugin_KR1HBZs4kY,
  prefetch_client_sFdSHx5gaE,
  plugin_CGAvNNqqy8,
  sentry_client_qJAwkxuHZw,
  sentry_client_config_o34nk2sJbg,
  directives_BRbw7yolFT,
  i18n_GS1gL0vY8V,
  chunk_reload_client_RK9Gq86PkW,
  validate_fk94mvjWUp,
  vueQuery_HbXfDZmQp2
]