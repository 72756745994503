import validate from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.17.5_@unocss+reset@0.63.3_eslint@9.10.0_floating-vue@5.2.2_jwt-dec_syiahgqhxrfd6v4d3sr3lvmpwu/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/prnews/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.17.5_@unocss+reset@0.63.3_eslint@9.10.0_floating-vue@5.2.2_jwt-dec_syiahgqhxrfd6v4d3sr3lvmpwu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/prnews/middleware/auth.ts"),
  "not-auth": () => import("/usr/prnews/middleware/notAuth.ts"),
  publisher: () => import("/usr/prnews/middleware/publisher.ts"),
  "publisher-flow": () => import("/usr/prnews/middleware/publisherFlow.ts"),
  "uncompleted-survey": () => import("/usr/prnews/middleware/uncompletedSurvey.ts")
}