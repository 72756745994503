import { NotificationType } from '~/src/enums/NotificationType'
import type { INotification } from '~/src/interfaces/INotification'
import type { Optional } from '~/src/types/Optional'

const notifications = ref<INotification[]>([])

let notificationTimer: ReturnType<typeof setTimeout> | null = null

const TIMEOUT = 3000

export default function useNotification () {
  const add = (notification: Optional<INotification, 'type' | 'title'>) => {
    if (!notification.type) {
      notification.type = NotificationType.Info
    }

    if (!notification.title) {
      notification.title = ''
    }

    notifications.value.unshift(notification as INotification)

    if (notificationTimer !== null) {
      return
    }

    setTimer()
  }

  const remove = () => {
    notifications.value.pop()
  }

  const setTimer = () => {
    notificationTimer = setTimeout(() => {
      remove()

      if (notifications.value.length === 0) {
        notificationTimer = null
        return
      }

      setTimer()
    }, TIMEOUT)
  }

  return { add, remove, notifications }
}
