import type {
  DehydratedState,
  VueQueryPluginOptions
} from '@tanstack/vue-query'
import {
  VueQueryPlugin,
  QueryClient,
  hydrate,
  dehydrate
} from '@tanstack/vue-query'

declare module "#app" {
  interface NuxtApp {
    $clearQueryClient: () => void
  }
}

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>('vue-query')

  // Modify your Vue Query global settings here
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 3000,
        networkMode: 'offlineFirst',
        refetchOnWindowFocus: false
      }
    }
  })
  const options: VueQueryPluginOptions = { queryClient }

  nuxt.vueApp.use(VueQueryPlugin, options)

  if (process.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryState.value = dehydrate(queryClient)
    })
  }

  if (process.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value)
    })
  }

  nuxt.provide('clearQueryClient', () => {
    queryClient.clear()
  })
})
